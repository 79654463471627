import PropTypes from 'prop-types';
import { AutoAwesome } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { useMutation } from 'react-query';
import vlqrApi from 'utils/vlqrApi';
import valImg from './val.png';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import useVoiceTranscriptionsForActivity from 'api-new/useVoiceTranscriptionsForActivity';
import { Trans, useTranslation } from 'react-i18next';

const NoReportsPlaceholder = ({ focusGroupId, onReportsGenerated }) => {
    const { t } = useTranslation();
    const { mutate: generateReports, isLoading: isGeneratingReports } = useMutation(() => {
        return vlqrApi.post(`focusgroups/${focusGroupId}/generateAiSummaryReports`);
    }, {
        onSuccess: (data, variables, context) => {
            onReportsGenerated?.();
        }
    });

    const { data: voiceTranscriptions, isLoading: isLoadingVoiceTranscriptions } = useVoiceTranscriptionsForActivity(focusGroupId);

    if (isLoadingVoiceTranscriptions) {
        return <LoadingPlaceholder />;
    }

    const isLackingVoiceTranscriptions = voiceTranscriptions.length === 0;

    const missingVoiceTranscriptsAlert =
        <Alert severity='warning' variant='filled' sx={{ maxWidth: '40em' }}>
            <AlertTitle>{ t('aiReports.noVoiceTranscriptionsAvailable') }</AlertTitle>

            <Trans
                i18nKey="aiReports.noVoiceTranscriptionsExplanation"
                components={{ emailSupportLink: <Link color="warning.contrastText" href="mailto:support@visionslive.com" /> }}
            />
        </Alert>;

    const generateReportsButton =
        <LoadingButton
            loading={isGeneratingReports}
            loadingPosition="start"
            startIcon={<AutoAwesome />}
            variant="contained"
            color="newPrimary"
            size="large"
            onClick={() => generateReports()}
        >
            { t('aiReports.generateAISummary') }
        </LoadingButton>;

    return (
        <Stack
            alignItems='center'
            sx={{ backgroundColor: 'grey.10', px: 4, py: 8, borderRadius: 2 }}
            gap={3}
        >
            <Box sx={{ borderRadius: '100%', height: 140, width: 140, overflow: 'hidden' }}>
                <img src={valImg} alt="val" height="100%" width="auto" />
            </Box>

            <Stack gap={1} alignItems='center' sx={{ width: '40em' }}>
                <Typography variant="h5" sx={{ color: 'grey.2' }}>
                    { t('aiReports.meetVal') }
                </Typography>

                <Typography variant="body1" align='center'>
                    { t('aiReports.meetValBlurb') }
                </Typography>
            </Stack>

            { isLackingVoiceTranscriptions
                ? missingVoiceTranscriptsAlert
                : generateReportsButton
            }
        </Stack>
    );
};

NoReportsPlaceholder.propTypes = {
    focusGroupId: PropTypes.string,
    onReportsGenerated: PropTypes.func
};

export default NoReportsPlaceholder;
