import { QuestionMark, Whatshot } from '@mui/icons-material';
import { Enumify } from 'enumify';
import PollActivityIcon from 'components/activity/SetupActivity/PollActivityIcon';
import TextActivityIcon from 'components/activity/SetupActivity/TextActivityIcon';

/**
 * Specifies what kind of stim-based session activity is being described.
 */
class ActivityType extends Enumify {
    static TextPrompt = new ActivityType({
        icon: TextActivityIcon
    });

    static Poll = new ActivityType({
        icon: PollActivityIcon
    });

    static Heatmap = new ActivityType({
        icon: Whatshot // Flame
    });

    static Unknown = new ActivityType({
        icon: QuestionMark
    });

    static _ = this.closeEnum();

    constructor (options) {
        super();
        this.icon = options.icon;
    }
}

export default ActivityType;
