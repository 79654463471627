import React from 'react';
import { SvgIcon } from '@mui/material';
import { mdiFormatListNumbered } from '@mdi/js';

const PollActivityIcon = () => (
    <SvgIcon>
        <path d={mdiFormatListNumbered} />
    </SvgIcon>
);

export default PollActivityIcon;