/**
 * Specifies what kind of activity is being described.
 * @readonly
 * @enum {string}
 */
const ACTIVITY_TYPE = {
    TEXT_PROMPT: 'TextPrompt',
    POLL: 'Poll',
    HEATMAP: 'Heatmap'
};

export default ACTIVITY_TYPE;
