import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Alert, AlertTitle, Autocomplete, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { ArrowForward, UploadFile } from '@mui/icons-material';
import {parse} from 'csv-parse/browser/esm/sync';
import _ from 'lodash';
import useParticipantsForActivity from 'api-new/useParticipantsForActivity';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import { DateTime } from 'luxon';
import vlqrApi from 'utils/vlqrApi';
import { useMutation } from 'react-query';

// Note that translations haven't been specified as of yet. The tool is aimed at internal users (dev/test) only and
// would be revisited design-wise should we target external users.

const VoiceTranscriptionsImporter = ({ focusGroupId }) => {
    const [importDidSucceed, setImportDidSucceed] = useState(false);
    const { data: focusGroupParticipants, isLoading: isLoadingParticipants } = useParticipantsForActivity(focusGroupId);
    const { mutate: replaceVoiceTranscriptions, isLoading: isProcessingRequest } = useMutation(voiceTranscriptions => {
        return vlqrApi.post(`focusGroups/${focusGroupId}/voiceTranscriptions`, voiceTranscriptions);
    }, {
        onSuccess: () => {
            setImportDidSucceed(true);
        }
    });

    const [filename, setFilename] = useState(null);
    const [csvData, setCsvData] = useState(null);
    const transcriptParticipants = _.uniq(csvData?.map(x => x.User));
    const [participantMapping, setParticipantMapping] = useState({});

    const handleFileUpload = (event, b, c, d) => {
        if(!event.target.files) {
            return;
        }

        const file = event.target.files[0];
        const { name } = file;
        setFilename(name);

        const reader = new FileReader();

        reader.onload = (evt) => {
            if (!evt?.target?.result) {
                return;
            }

            // Read in the data, stripping out any BOMs that are present.
            const rawData = evt.target.result.replace(/^\uFEFF/, '');

            const records = parse(rawData, {
                bom: true,
                columns: true, // ['Time', 'User', 'Transcript']
                delimiter: ',',
                trim: true,
                skip_empty_lines: true
            });

            setCsvData(records);
        };

        reader.readAsText(file);
    };

    if (isLoadingParticipants) {
        return <LoadingPlaceholder />;
    }

    const focusGroupParticipantIds = focusGroupParticipants.map(x => x.projectParticipantId);
    const focusGroupParticipantsAsObject = _.keyBy(focusGroupParticipants, 'projectParticipantId');
    const isParticipantMapIncomplete = Boolean(transcriptParticipants.find(x => Boolean(participantMapping[x]) === false));

    const handleSubmit = () => {
        const payload = csvData.map(row => {
            // Dates in csv formatted as follows: e.g. `6/26/2024 9:28:19 PM`
            const timestamp = DateTime.fromFormat(row.Time, 'M/d/y h:m:s a').toUTC().toISO();

            return {
                participantId: participantMapping[row.User],
                timestamp: timestamp,
                text: row.Transcript,
                translatedText: null // ignored for now
            };
        });

        replaceVoiceTranscriptions(payload);
    };

    if (importDidSucceed) {
        return (
            <Alert severity='success'>
                <AlertTitle>Done!</AlertTitle>
                Voice transcriptions were imported successfully.
            </Alert>
        );
    }

    return (
        <Stack gap={3}>
            <Box>
                <Typography variant="h6" gutterBottom>Import Transcriptions</Typography>
                <Typography variant="body1">Please be aware of the limitations of this tool. Should only be used by the dev team for now due to limited validation and error handling.</Typography>
            </Box>

            <Button
                size="large"
                component="label"
                variant="outlined"
                startIcon={<UploadFile />}
                color={csvData ? 'success' : 'newPrimary' }
            >
                { filename ?? 'Upload CSV' }
                <input type="file" accept=".csv" hidden onChange={handleFileUpload} />
            </Button>

            { transcriptParticipants.length > 0 &&
                <Stack gap={2} sx={{ padding: 2, border: 'thin solid grey', borderColor: 'grey.9', borderRadius: 1 }}>
                    <Typography variant="body2">Use the fields below to map transcript names to participants within this session</Typography>
                    <Stack gap={1}>
                        { transcriptParticipants.map(participantName => (
                            <Stack
                                key={`participant-mapper-item-${participantName}`}
                                direction='row'
                                alignItems='center'
                                gap={1}
                            >
                                <Typography variant="subtitle1">
                                    { participantName }
                                </Typography>

                                <ArrowForward />

                                <Autocomplete
                                    size='small'
                                    sx={{ minWidth: '20em' }}
                                    options={focusGroupParticipantIds}
                                    getOptionLabel={(participantId) => focusGroupParticipantsAsObject[participantId].name}
                                    renderInput={(params) => <TextField {...params} placeholder="Choose Target Participant" />}
                                    onChange={(event, value) => setParticipantMapping({ ...participantMapping, [participantName]: value }) }
                                    renderOption={(props, participantId) => {
                                        return (
                                            <li {...props} key={`targetParticipant-list-item-${participantId}`}>
                                                {focusGroupParticipantsAsObject[participantId].name}
                                            </li>
                                        );
                                    }}
                                />
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
            }

            { transcriptParticipants.length > 0 &&
                <Button
                    variant="contained"
                    sx={{ width: '10em' }}
                    disabled={isParticipantMapIncomplete || isProcessingRequest}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            }
        </Stack>
    );
};

VoiceTranscriptionsImporter.propTypes = {
    focusGroupId: PropTypes.string
};

export default VoiceTranscriptionsImporter;
